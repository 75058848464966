<template>
  <div>
    <div class="flex flex-row">
        <div class="round">
            <input type="checkbox" :id="`${checkboxId}`" :value="value" />
            <label :for="`${checkboxId}`"></label>
        </div>
        <div class="ml-8 mr-4">
            <label for="" :style="`${underline === true ? 'text-decoration: underline;' : 'text-decoration: none;'}`">{{name}}</label>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "value", "checkboxId", 'underline'],
};
</script>

<style scoped>
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #573C81;
  border-color: #573C81;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
</style>

