import axios from 'axios';

const login = async (email, password) => {
    let auth = await axios.post(`${process.env.VUE_APP_URL_API}/users/login`, {
        email: email,
        password: password,
    });
    return auth;
}

const register = async (
    email,
    password,
    confirmPassword,
    titileName,
    firstName,
    lastName,
    cardNumber,
    positionWorkName,
    phone,
    phoneExtention,
    fax,
    mobilePhone,
    status,
    addressName,
    subDistrict,
    district,
    province,
    zipCode,
) => {
    let auth = await axios.post(`${process.env.VUE_APP_URL_API}/users/register`, {
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        titileName: titileName,
        firstName: firstName,
        lastName: lastName,
        cardNumber: cardNumber,
        positionWorkName: positionWorkName,
        phone: phone,
        phoneExtention: phoneExtention,
        fax: fax,
        mobilePhone: mobilePhone,
        status: status,
        address: {
            addressName: addressName,
            subDistrict: subDistrict,
            district: district,
            province: province,
            zipCode: zipCode,
        }
    });
    return auth;
}

const forgotPassword = async (email) => {
    let auth = await axios.post(`${process.env.VUE_APP_URL_API}/users/forgotpassword`, {
        email: email
    });
    return auth;
}

const changePassword = async (token, cardNumber, password, confirmPassword) => {
    let configToken = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    let auth = await axios.put(`${process.env.VUE_APP_URL_API}/users/changepassword`, {
        cardNumber: cardNumber,
        password: password,
        confirmPassword: confirmPassword
    }, configToken);
    return auth;
}

const profile = async (token) => {
    let configToken = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    let auth = await axios.get(`${process.env.VUE_APP_URL_API}/users/profile`, configToken);
    return auth;
}

const contact = async (firstName, lastName, email, companyName, subject) => {
    let auth = await axios.post(`${process.env.VUE_APP_URL_API}/users/contact`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        companyName: companyName,
        subject: subject,
    });
    return auth;
}

export default {
    login,
    register,
    forgotPassword,
    changePassword,
    profile,
    contact,
}