<template>
  <div class="container mb-48">
    <div class="h-full w-full mt-12">
      <div
        class="container h-full max-h-full rounded overflow-hidden"
        style="border-width: 1px"
      >
        <div class="title mt-4 mb-8" align="center">ลงทะเบียน</div>

        <div
          class="mb-8 grid grid-cols-5 gap-x-36 items-center justify-center justify-items-center"
        >
          <div>
            <div class="roundedCircleActive">1</div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <div class="roundedCircleActive">2</div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <div class="roundedCircle">3</div>
          </div>

          <div class="gapTextActive">บัญชีผู้ใช้งาน</div>

          <div class="gapText mt-1"></div>

          <div class="gapTextActive mt-1">ข้อมูลส่วนตัว</div>

          <div class="gapText mt-1"></div>

          <div class="gapText mt-1">ที่อยู่</div>
        </div>

        <div class="status mb-2">สถานะผู้ขอทุน</div>

        <div class="flex flex-row mb-4">
          <checkboxComponent
            name="บุคคลทั่วไป"
            checkboxId="checkbox1"
            @click="checkboxCondition('checkbox1')"
          />

          <checkboxComponent
            name="หน่วยงานเอกชน"
            checkboxId="checkbox2"
            @click="checkboxCondition('checkbox2')"
          />

          <checkboxComponent
            name="หน่วยงานของราชการ"
            checkboxId="checkbox3"
            @click="checkboxCondition('checkbox3')"
          />
        </div>

        <div class="grid grid-cols-3 gap-x-4">
          <div class="">
            <label class="mb-2">คำนำหน้า</label>
            <div class="mb-1"></div>

            <div v-if="titleName === undefined">
              <select id="titleNameId" @change="checkTitleName()">
                <option value="">กรุณาเลือก</option>
                <option value="นาย">นาย</option>
                <option value="นาง">นาง</option>
                <option value="นางสาว">นางสาว</option>
              </select>
            </div>

            <div v-else>
              <div v-if="titleName === 'นาย'">
                <select id="titleNameId" @click="checkTitleName()">
                  <option value="นาย">นาย</option>
                  <option value="นาง">นาง</option>
                  <option value="นางสาว">นางสาว</option>
                </select>
              </div>

              <div v-else-if="titleName === 'นาง'">
                <select id="titleNameId" @click="checkTitleName()">
                  <option value="นาง">นาง</option>
                  <option value="นางสาว">นางสาว</option>
                  <option value="นาย">นาย</option>
                </select>
              </div>

              <div v-else-if="titleName === 'นางสาว'">
                <select id="titleNameId" @click="checkTitleName()">
                  <option value="นางสาว">นางสาว</option>
                  <option value="นาย">นาย</option>
                  <option value="นาง">นาง</option>
                </select>
              </div>
            </div>
          </div>

          <div class="">
            <label class="mb-2">ชื่อ</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="ชื่อ"
              v-model="firstName"
            />
          </div>

          <div class="">
            <label class="mb-2">นามสกุล</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="นามสกุล"
              v-model="lastName"
            />
          </div>
        </div>

        <div class="">
          <label class="mb-2">เลขประจำตัวประชาชน</label>
          <div class="mb-1"></div>
          <input
            class="border-2 border-red-500 w-full mb-4"
            type="text"
            placeholder="เลขประจำตัวประชาชน"
            v-model="cardNumber"
          />
        </div>

        <div class="">
          <label class="mb-2">ตำแหน่ง</label>
          <div class="mb-1"></div>
          <input
            class="border-2 border-red-500 w-full mb-4"
            type="text"
            placeholder="ตำแหน่ง"
            v-model="positionWorkName"
          />
        </div>

        <div class="grid grid-cols-2 gap-x-4">
          <div class="">
            <label class="mb-2">โทรศัพท์</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="โทรศัพท์"
              v-model="phone"
            />
          </div>

          <div class="">
            <label class="mb-2">เบอร์ต่อ</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="เบอร์ต่อ"
              v-model="phoneExtention"
            />
          </div>
        </div>

        <div class="">
          <label class="mb-2">โทรสาร</label>
          <div class="mb-1"></div>
          <input
            class="border-2 border-red-500 w-full mb-4"
            type="text"
            placeholder="โทรสาร"
            v-model="fax"
          />
        </div>

        <div class="">
          <label class="mb-2">โทรศัพท์มือถือ</label>
          <div class="mb-1"></div>
          <input
            class="border-2 border-red-500 w-full mb-4"
            type="text"
            placeholder="โทรศัพท์มือถือ"
            v-model="mobilePhone"
          />
        </div>

        <div class="flex flex-row items-center mb-8 mt-12">
          <div class="text-left w-1/2">
            <button class="buttonOutline" @click="deleteCount()" type="button">
              ก่อนหน้า
            </button>
          </div>

          <div class="text-right w-1/2">
            <button @click="addCount()" type="button">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ref } from "vue";
import checkboxComponent from "@/components/checkbox/checkboxComponent";
export default {
  components: {
    checkboxComponent,
  },
  mounted() {
    this.getCheckbox();
    this.checkboxCondition();
    this.getData();
  },
  computed: {
    ...mapState({
      registerCount: (state) => state.register.registerCount,
      registerPageTwo: (state) => state.register.registerPageTwo,
    }),
  },
  methods: {
    ...mapActions("register", ["actionsRegisterCountAdd"]),
    ...mapActions("register", ["actionsRegisterPageTwoAdd"]),
    deleteCount() {
      this.actionsRegisterCountAdd(1);
    },

    addCount() {
      this.actionsRegisterCountAdd(3);
      this.actionsRegisterPageTwoAdd({
        titleName: this.titleName,
        firstName: this.firstName,
        lastName: this.lastName,
        cardNumber: this.cardNumber,
        positionWorkName: this.positionWorkName,
        phone: this.phone,
        phoneExtention: this.phoneExtention,
        fax: this.fax,
        mobilePhone: this.mobilePhone,
        status: this.status,
      });
    },

    checkTitleName() {
      let titleNameId = document.getElementById("titleNameId");
      if (titleNameId.value === "นาย") {
        this.titleName = "นาย";
      } else if (titleNameId.value === "นาง") {
        this.titleName = "นาง";
      } else if (titleNameId.value === "นางสาว") {
        this.titleName = "นางสาว";
      }
    },

    getData() {
      this.titleName = this.registerPageTwo.titleName;
      this.firstName = this.registerPageTwo.firstName;
      this.lastName = this.registerPageTwo.lastName;
      this.cardNumber = this.registerPageTwo.cardNumber;
      this.positionWorkName = this.registerPageTwo.positionWorkName;
      this.phone = this.registerPageTwo.phone;
      this.phoneExtention = this.registerPageTwo.phoneExtention;
      this.fax = this.registerPageTwo.fax;
      this.mobilePhone = this.registerPageTwo.mobilePhone;
      this.status = this.registerPageTwo.status;
    },

    getCheckbox() {
      if (this.registerPageTwo.status === 'บุคคลทั่วไป') {
        document.getElementById("checkbox1").checked = true;
      } else if (this.registerPageTwo.status === 'หน่วยงานเอกชน') {
        document.getElementById("checkbox2").checked = true;
      } else if (this.registerPageTwo.status === 'หน่วยงานของราชการ') {
        document.getElementById("checkbox3").checked = true;
      }
    },

    checkboxCondition(id) {
      if (id === "checkbox1") {
        document.getElementById("checkbox1").checked = true;
        document.getElementById("checkbox2").checked = false;
        document.getElementById("checkbox3").checked = false;
        this.status = "บุคคลทั่วไป";
      } else if (id === "checkbox2") {
        document.getElementById("checkbox1").checked = false;
        document.getElementById("checkbox2").checked = true;
        document.getElementById("checkbox3").checked = false;
        this.status = "หน่วยงานเอกชน";
      } else if (id === "checkbox3") {
        document.getElementById("checkbox1").checked = false;
        document.getElementById("checkbox2").checked = false;
        document.getElementById("checkbox3").checked = true;
        this.status = "หน่วยงานของราชการ";
      }
    },
  },
  setup() {
    let titleName = ref(null);
    let firstName = ref(null);
    let lastName = ref(null);
    let cardNumber = ref(null);
    let positionWorkName = ref(null);
    let phone = ref(null);
    let phoneExtention = ref(null);
    let fax = ref(null);
    let mobilePhone = ref(null);
    let status = ref(null);
    return {
      titleName,
      firstName,
      lastName,
      cardNumber,
      positionWorkName,
      phone,
      phoneExtention,
      fax,
      mobilePhone,
      status,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */
  font-weight: bold;
  color: #353535;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

select {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

button {
  background: #573c81;
  border-radius: 5px;
  height: 50px;
  color: white;
  width: 194px;
}

.buttonOutline {
  background: none;
  border-radius: 5px;
  border-width: 1px;
  border-color: #573c81;
  height: 50px;
  color: #573c81;
  width: 194px;
}

.roundedCircleActive {
  width: 44px;
  height: 44px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.roundedCircle {
  width: 44px;
  height: 44px;
  color: #c4c4c4;
  background: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  border-width: 1px;
}

.roundedText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

hr {
  border-color: #573c81;
  width: 500px;
}

.gapTextActive {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #573c81;
}

.gapText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #c4c4c4;
}

.status {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>