<template>
  <div class="container mb-48">
    <div class="h-full w-full mt-12">
      <div
        class="container h-full max-h-full rounded overflow-hidden"
        style="border-width: 1px"
      >
        <div class="title mt-4 mb-8" align="center">ลงทะเบียน</div>

        <div
          class="
            mb-8
            grid grid-cols-5
            gap-x-36
            items-center
            justify-center justify-items-center
          "
        >
          <div>
            <div class="roundedCircleActive">1</div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <div class="roundedCircle">2</div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <div class="roundedCircle">3</div>
          </div>

          <div class="gapTextActive">บัญชีผู้ใช้งาน</div>

          <div class="gapText mt-1"></div>

          <div class="gapText mt-1">ข้อมูลส่วนตัว</div>

          <div class="gapText mt-1"></div>

          <div class="gapText mt-1">ที่อยู่</div>
        </div>

        <label class="mb-2">อีเมล</label>
        <div class="mb-1"></div>
        <input
          class="border-2 border-red-500 w-full mb-4"
          type="email"
          placeholder="อีเมล"
          v-model="email"
        />

        <label>รหัสผ่าน (ต้องมีความยาว 8-20 ตัวอักษร)</label>
        <div class="mb-1"></div>
        <div class="relative flex justify-items-end justify-end mb-4">
          <span
            class="
              absolute
              inset-y-0
              flex
              items-center
              justify-items-center justify-center
              z-10
            "
            @click="checkShowHidePassword1()"
          >
            <font-awesome-icon
              class="fa-2x pr-4"
              :icon="`${iconPassword1 % 2 === 0 ? 'eye-slash' : 'eye'}`"
              color="#C6C6C6"
            />
          </span>
          <input
            id="passwordId1"
            placeholder="รหัสผ่าน"
            type="password"
            v-model="password"
          />
        </div>

        <label>ยืนยันรหัสผ่าน (ต้องมีความยาว 8-20 ตัวอักษร)</label>
        <div class="mb-1"></div>
        <div class="relative flex justify-items-end justify-end">
          <span
            class="
              absolute
              inset-y-0
              flex
              items-center
              justify-items-center justify-center
              z-10
            "
            @click="checkShowHidePassword2()"
          >
            <font-awesome-icon
              class="fa-2x pr-4"
              :icon="`${iconPassword2 % 2 === 0 ? 'eye-slash' : 'eye'}`"
              color="#C6C6C6"
            />
          </span>
          <input
            id="passwordId2"
            placeholder="ยืนยันรหัสผ่าน"
            type="password"
            v-model="confirmPassword"
          />
        </div>

        <div align="right" class="mb-8 mt-12">
          <button @click="addCount()" type="button">ถัดไป</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ref } from "vue";
export default {
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({
      registerPageOne: (state) => state.register.registerPageOne,
    }),
  },
  methods: {
    ...mapActions("register", ["actionsRegisterCountAdd"]),
    ...mapActions("register", ["actionsRegisterPageOneAdd"]),
    addCount() {
      this.actionsRegisterCountAdd(2);
      this.actionsRegisterPageOneAdd({
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
      });
    },
    getData() {
      this.email = this.registerPageOne.email;
      this.password = this.registerPageOne.password;
      this.confirmPassword = this.registerPageOne.confirmPassword;
    },
    checkShowHidePassword1() {
      this.iconPassword1 = this.iconPassword1 + 1;
      if (this.iconPassword1 % 2 === 0) {
        document.getElementById('passwordId1').type = 'text';
      } else {
        document.getElementById('passwordId1').type = 'password';
      }
    },
    checkShowHidePassword2() {
      this.iconPassword2 = this.iconPassword2 + 1;
      if (this.iconPassword2 % 2 === 0) {
        document.getElementById('passwordId2').type = 'text';
      } else {
        document.getElementById('passwordId2').type = 'password';
      }
    },
  },
  setup() {
    const email = ref(null);
    const password = ref(null);
    const confirmPassword = ref(null);
    const iconPassword1 = ref(1);
    const iconPassword2 = ref(1);
    return { email, password, confirmPassword, iconPassword1, iconPassword2 };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */
  font-weight: bold;
  color: #353535;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

button {
  background: #573c81;
  border-radius: 5px;
  height: 50px;
  color: white;
  width: 194px;
}

.roundedCircleActive {
  width: 44px;
  height: 44px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.roundedCircle {
  width: 44px;
  height: 44px;
  color: #c4c4c4;
  background: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  border-width: 1px;
}

.roundedText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

hr {
  border-color: #573c81;
  width: 500px;
}

.gapTextActive {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #573c81;
}

.gapText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #c4c4c4;
}
</style>