<template>
  <div class="container mb-48">
    <div class="h-full w-full mt-12">
      <div
        class="container h-full max-h-full rounded overflow-hidden"
        style="border-width: 1px"
      >
        <div class="title mt-4 mb-8" align="center">ลงทะเบียน</div>

        <div
          class="mb-8 grid grid-cols-5 gap-x-36 items-center justify-center justify-items-center"
        >
          <div>
            <div class="roundedCircleActive">1</div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <div class="roundedCircleActive">2</div>
          </div>

          <div>
            <hr />
          </div>

          <div>
            <div class="roundedCircleActive">3</div>
          </div>

          <div class="gapTextActive">บัญชีผู้ใช้งาน</div>

          <div class="gapText mt-1"></div>

          <div class="gapTextActive mt-1">ข้อมูลส่วนตัว</div>

          <div class="gapText mt-1"></div>

          <div class="gapTextActive mt-1">ที่อยู่</div>
        </div>

        <div class="">
          <label class="mb-2">ที่อยู่</label>
          <div class="mb-1"></div>
          <textarea
            class="mb-4"
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="addressName"
          ></textarea>
        </div>

        <div class="grid grid-cols-2 gap-x-4">
          <div class="">
            <label class="mb-2">รหัสไปรษณีย์</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="รหัสไปรษณีย์"
              v-model="zipCode"
            />
          </div>

          <div class="">
            <label class="mb-2">จังหวัด</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="จังหวัด"
              v-model="province"
            />
          </div>

          <div class="">
            <label class="mb-2">อำเภอ/เขต</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="อำเภอ/เขต"
              v-model="district"
            />
          </div>

          <div class="">
            <label class="mb-2">ตำบล/แขวง</label>
            <div class="mb-1"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="ตำบล/แขวง"
              v-model="subDistrict"
            />
          </div>
        </div>

        <div class="flex flex-row items-center mb-8 mt-12">
          <div class="text-left w-1/2">
            <button class="buttonOutline" @click="deleteCount()" type="button">
              ก่อนหน้า
            </button>
          </div>

          <div class="text-right w-1/2">
            <button @click="addCount()" type="button">เสร็จสิ้น</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ref } from "vue";
import useAuth from "../../hooks/useAuth";
export default {
  computed: {
    ...mapState({
      registerCount: (state) => state.register.registerCount,
      registerPageOne: (state) => state.register.registerPageOne,
      registerPageTwo: (state) => state.register.registerPageTwo,
      registerPageThree: (state) => state.register.registerPageThree,
    }),
  },
  methods: {
    ...mapActions("register", ["actionsRegisterCountAdd"]),
    ...mapActions("register", ["actionsRegisterPageThreeAdd"]),

    deleteCount() {
      this.actionsRegisterCountAdd(2);
    },

    addCount() {
      // this.actionsRegisterCountAdd(3);
      this.actionsRegisterPageThreeAdd({
        addressName: this.addressName,
        subDistrict: this.subDistrict,
        district: this.district,
        province: this.province,
        zipCode: this.zipCode,
      });

      useAuth.register(
        this.registerPageOne.email,
        this.registerPageOne.password,
        this.registerPageOne.confirmPassword,
        this.registerPageTwo.titleName,
        this.registerPageTwo.firstName,
        this.registerPageTwo.lastName,
        this.registerPageTwo.cardNumber,
        this.registerPageTwo.positionWorkName,
        this.registerPageTwo.phone,
        this.registerPageTwo.phoneExtention,
        this.registerPageTwo.fax,
        this.registerPageTwo.mobilePhone,
        this.registerPageTwo.status,
        this.registerPageThree.addressName,
        this.registerPageThree.subDistrict,
        this.registerPageThree.district,
        this.registerPageThree.province,
        this.registerPageThree.zipCode
      ).then(response => {
        if (response.data.status === true) {
          this.$swal({
            title: "ลงทะเบียนสำเร็จ",
            icon: 'success',
            // html: `<div align='center'><img src='${process.env.VUE_APP_URL_API}/icon/success.svg' width='100px;' height='100px;' /></div>`,
            confirmButtonText: "กลับไปหน้าเข้าสู่ระบบ",
          });
          this.$router.push('/login');
        }
      });
    },
  },
  setup() {
    let addressName = ref(null);
    let subDistrict = ref(null);
    let district = ref(null);
    let province = ref(null);
    let zipCode = ref(null);
    return { addressName, subDistrict, district, province, zipCode };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */
  font-weight: bold;
  color: #353535;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

textarea {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 105px;
  padding-left: 15px;
  padding-top: 5px;
}

select {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

button {
  background: #573c81;
  border-radius: 5px;
  height: 50px;
  color: white;
  width: 194px;
}

.buttonOutline {
  background: none;
  border-radius: 5px;
  border-width: 1px;
  border-color: #573c81;
  height: 50px;
  color: #573c81;
  width: 194px;
}

.roundedCircleActive {
  width: 44px;
  height: 44px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.roundedCircle {
  width: 44px;
  height: 44px;
  color: #c4c4c4;
  background: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  border-width: 1px;
}

.roundedText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

hr {
  border-color: #573c81;
  width: 500px;
}

.gapTextActive {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #573c81;
}

.gapText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #c4c4c4;
}

.status {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>