<template>
  <div>
    <div v-if="registerCount === 1">
      <registerComponent1 />
    </div>

    <div v-else-if="registerCount === 2">
      <registerComponent2 />
    </div>

    <div v-else-if="registerCount === 3">
      <registerComponent3 />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import registerComponent1 from "@/components/register/registerComponent1";
import registerComponent2 from "@/components/register/registerComponent2";
import registerComponent3 from "@/components/register/registerComponent3";
export default {
  components: {
    registerComponent1,
    registerComponent2,
    registerComponent3,
  },
  mounted() {
    this.checkRegister();
  },
  computed: {
    ...mapState({
      registerCount: (state) => state.register.registerCount,
    }),
  },
  methods: {
      ...mapActions("register", ["actionsRegisterCountAdd"]),
      ...mapActions("register", ["actionsRegisterPageOneAdd"]),
      ...mapActions("register", ["actionsRegisterPageTwoAdd"]),
      ...mapActions("register", ["actionsRegisterPageThreeAdd"]),
      checkRegister() {
          if (this.registerCount > 1) {
              this.actionsRegisterCountAdd(1);
              this.actionsRegisterPageOneAdd({});
              this.actionsRegisterPageTwoAdd({});
              this.actionsRegisterPageThreeAdd({});
          }
      }
  },
  
//   methods: {
//     ...mapActions("register", ["actionsRegisterCount"]),
//     addCount() {
//         this.actionsRegisterCount(this.registerCount + 1);
//     }
//   },
};
</script>